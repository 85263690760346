import React, { useState } from 'react';
import GuestModeWarning from './GuestModeWarning';

export default function Leaderboards() {
  const [showWarning, setShowWarning] = useState(false);

  const handleAction = () => {
    const isGuest = localStorage.getItem('guestMode') === 'true';
    if (isGuest) {
      setShowWarning(true);
      return;
    }
    // Normal functionality here
  };

  return (
    <div>
      <h1>Leaderboards</h1>
      {/* Your leaderboards content */}
      <GuestModeWarning 
        isOpen={showWarning} 
        onClose={() => setShowWarning(false)} 
      />
    </div>
  );
}
