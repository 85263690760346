import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Leaderboards from "./components/Leaderboards";
import QuizHistory from "./components/QuizHistory";
import Notifications from "./components/Notifications";
import RoutingLayout from "./RoutingLayout";
import SignUp from "./authentication/SignUp";
import ForgotPassword from "./authentication/ForgotPassword";
import Login from "./authentication/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./ui/Profile";
import Settings from './ui/Settings'
import ConfirmNewPassword from "./authentication/ConfirmNewPassword";
import QuizHistoryDetail from "./components/QuizHistoryDetail";

const App = () => {
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/resetpassword/:token" element={<ConfirmNewPassword />} />
        <Route path="/login" element={<Login />} />

        <Route path="/" element={<RoutingLayout />}>
          <Route index element={<Home />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/leaderboards" element={<Leaderboards />} />
          <Route path="/quiz-history" element={<QuizHistory />} />
          <Route path="/quiz-history/:id" element={<QuizHistoryDetail />} />
          <Route path="/notifications" element={<Notifications />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
