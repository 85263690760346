import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "../scss/quizhistory.scss";

const QuizHistory = () => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1); // Current page state
  const [totalPages, setTotalPages] = useState(1); // Total pages state

  const handleError = (error) => {
    console.error("An error occurred:", error);
    setError("An unexpected error occurred. Please try again later.");
  };

  const fetchHistory = async (page) => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      setError("Authentication token is missing");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(`https://law-quiz-backend.onrender.com/api/results?page=${page}&limit=10`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      });

      console.log("API Response:", response); // Log the full response
      setHistory(response.data);
      setTotalPages(Math.ceil(response.headers['x-total-count'] / 10)); // Adjust based on your API response
    } catch (error) {
      console.error("Error fetching history:", error);
      setError(error.response?.data?.error || "Failed to load quiz history");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHistory(page); // Fetch history when page changes
  }, [page]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (loading) return (
    <div className="quiz-history-container">
      <div className="quiz-history-card">
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </div>
    </div>
  );

  if (error) return (
    <div className="quiz-history-container">
      <div className="quiz-history-card">
        <div className="error-message">{error}</div>
      </div>
    </div>
  );

  return (
    <div className="quiz-history-container">
      <div className="quiz-history-card">
        <h2 className="quiz-history-title">Quiz History</h2>
        {history.length === 0 ? (
          <p className="no-history-msg">No quiz attempts yet.</p>
        ) : (
          history.map((result) => (
            <div key={result._id} className="quiz-history-item">
              <div className="quiz-history-item-title">
                Quiz Attempt - {formatDate(result.date)}
              </div>
              <div className="quiz-history-item-data">
                <div className="quiz-history-item-stats">
                  <div className="stat">
                    <span>Score</span>
                    <strong>{result.score}/{result.totalQuestions}</strong>
                  </div>
                  <div className="stat">
                    <span>Percentage</span>
                    <strong>
                      {((result.score / result.totalQuestions) * 100).toFixed(1)}%
                    </strong>
                  </div>
                  {result.duration && (
                    <div className="stat">
                      <span>Time Taken</span>
                      <strong>{result.duration} mins</strong>
                    </div>
                  )}
                </div>
              </div>
              <div className="quiz-history-actions">
                <Link 
                  to={`/quiz-history/${result._id}`} 
                  className="view-answers-btn"
                >
                  View Details
                </Link>
              </div>
            </div>
          ))
        )}
        {/* Pagination Bar */}
        <div className="pagination-bar">
          <button 
            onClick={() => setPage((prev) => Math.max(prev - 1, 1))} 
            disabled={page === 1}
          >
            Previous
          </button>
          <span>Page {page} of {totalPages}</span>
          <button 
            onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))} 
            disabled={page === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuizHistory;
