// Login.jsx
import React, { useState } from "react";
import "../authentication/scss/login.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import OpenEyeIcon from "../assets/svg/open-eye.svg";
import ClosedEyeIcon from "../assets/svg/closed-eye.svg";


export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const navigate = useNavigate();

  const togglePassword = () => {
    setPasswordVisibility((prev) => !prev);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Show loading toast
    const loadingToast = toast.loading("Logging in...");

    try {
      // First, attempt to login - with timeout
      const loginResponse = await axios.post("https://law-quiz-backend.onrender.com/auth/login", {
        email,
        password,
      }, { timeout: 5000 }); // 5 second timeout


      const { token, user } = loginResponse.data;

      // Store token and user info immediately
      localStorage.setItem("authToken", token);
      localStorage.removeItem("guestMode");
      
      // Clear local storage in parallel
      const clearLocalStorage = () => {
        localStorage.removeItem("quizState");
        localStorage.removeItem("currentQuiz");
      };

      // Clear quiz state in parallel
      const clearQuizState = async () => {
        try {
          await axios.delete("https://law-quiz-backend.onrender.com/api/quiz/state", {
            headers: { 
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            timeout: 3000 // 3 second timeout
          });
        } catch (clearError) {
          console.warn("Failed to clear quiz state:", clearError);
        }
      };

      // Run cleanup operations in parallel
      await Promise.all([
        clearQuizState(),
        Promise.resolve(clearLocalStorage())
      ]);

      // Update loading toast to success
      toast.update(loadingToast, {
        render: "Login successful!",
        type: "success",
        isLoading: false,
        autoClose: 2000
      });

      // Navigate after showing success toast
      setTimeout(() => {
        navigate("/");
      }, 500);

    } catch (error) {
      console.error("Login error:", error);
      
      // Update loading toast to error
      toast.update(loadingToast, {
        render: getErrorMessage(error),
        type: "error",
        isLoading: false,
        autoClose: 3000
      });
    }
  };

  // Helper function to get error message
  const getErrorMessage = (error) => {
    if (error.response) {
      if (error.response.status === 400) return "User not found!";
      if (error.response.status === 401) return "Wrong password!";
      return error.response.data.message || "An unexpected error occurred.";
    }
    if (error.code === 'ECONNABORTED') return "Request timed out. Please try again.";
    if (error.request) return "No response from server. Please check your connection.";
    return "Login failed. Please try again.";
  };

  return (
    <div className="login">
      <div className="container">
        <div className="login-content">
          <div className="login-card">
            <form className="login-form" onSubmit={handleSubmit}>
              <h2 className="login-title">Welcome Back</h2>
              <p className="login-subtitle">
                Please enter your details to sign in
              </p>

              <div className="form-group">
                <label>Email address</label>
                <div className="input-wrapper">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="Enter your email"
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Password</label>
                <div className="input-wrapper">
                  <input
                    type={passwordVisibility ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    placeholder="Enter your password"
                  />
                  <button
                    type="button"
                    className="password-toggle"
                    onClick={togglePassword}
                  >
                    {passwordVisibility ? (
                      <img src={OpenEyeIcon}></img>
                    ) : (
                      <img src={ClosedEyeIcon}></img>
                    )}
                  </button>
                </div>
              </div>

              <div className="remember-forgot">
                <label className="remember-me">
                  <input type="checkbox" />
                  <span>Remember me</span>
                </label>
                <Link to="/forgot-password" className="forgot-link">
                  Forgot password?
                </Link>
              </div>

              <button type="submit" className="login-button">
                Sign in
              </button>

              <div className="signup-prompt">
                Don't have an account?
                <Link to="/signup">Sign up</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
}
