import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../scss/guestModeWarning.scss';

const GuestModeWarning = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Login Required</h2>
        <p>Please log in to use this feature. Create an account if you don't have one!</p>
        <div className="modal-buttons">
          <button 
            className="login-button"
            onClick={() => navigate('/login')}
          >
            Log In
          </button>
          <button 
            className="cancel-button"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default GuestModeWarning; 