import React from "react";
import { Outlet } from "react-router-dom";
import "./scss/routinglayout.scss";
import Sidebar from "./ui/Sidebar";
import Navbar from "./components/Navbar";

export default function RoutingLayout() {
  return (
    <>
      <div className="sidebar-navbar-outlet">
        <Sidebar />
        <div className="navbar-outlet">
          <Navbar />
          <div className="outlet">
            <Outlet />
          </div>
        </div>
      </div>

      
    </>
  );
}
