// SignUp.jsx
import React, { useState } from "react";
import "../authentication/scss/signup.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import OpenEyeIcon from '../assets/svg/open-eye.svg'
import ClosedEyeIcon from "../assets/svg/closed-eye.svg";

const SignUp = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const navigate = useNavigate();

  const toggleInput = () => {
    setPasswordVisibility((prevState) => !prevState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validatePassword(password)) return;

    const loadingToast = toast.loading("Creating your account...");

    try {
      // Attempt to register - with timeout
      const response = await axios.post("https://law-quiz-backend.onrender.com/auth/signup", {
        name,
        surname,
        email,
        password,
      }, { timeout: 5000 });

      const { token, user } = response.data;

      // Store token and clear guest mode immediately
      localStorage.setItem("authToken", token);
      localStorage.removeItem("guestMode");
      
      // Initialize quiz state and clear local storage in parallel
      const initializeState = async () => {
        try {
          await axios.post("https://law-quiz-backend.onrender.com/api/quiz/init", {}, {
            headers: { 
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            timeout: 3000
          });
        } catch (initError) {
          console.warn("Failed to initialize quiz state:", initError);
        }
      };

      const clearStorage = () => {
        localStorage.removeItem("quizState");
        localStorage.removeItem("currentQuiz");
      };

      await Promise.all([
        initializeState(),
        Promise.resolve(clearStorage())
      ]);

      // Update loading toast to success
      toast.update(loadingToast, {
        render: "Account created successfully!",
        type: "success",
        isLoading: false,
        autoClose: 2000
      });

      // Navigate after showing success toast
      setTimeout(() => {
        navigate("/");
      }, 500);

    } catch (error) {
      console.error("Signup error:", error);
      
      // Update loading toast to error
      toast.update(loadingToast, {
        render: error.response?.data?.message || "Registration failed. Please try again.",
        type: "error",
        isLoading: false,
        autoClose: 3000
      });
    }
  };

  const inputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const validatePassword = (password) => {
    const strongPasswordRegex =
      /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[0-9])(?=.{8,})/;
    if (!password) {
      toast.warning("Please enter your password!");
      return false;
    } else if (!strongPasswordRegex.test(password)) {
      toast.warning(
        "Password must contain at least 8 characters, including numbers and special characters!"
      );
      return false;
    }
    return true;
  };

  return (
    <div className="signup">
      <div className="container">
        <div className="signup-content">
          <div className="signup-card">
            <form className="signup-form" onSubmit={handleSubmit}>
              <h2 className="signup-title">Create Account</h2>

              <p className="signup-subtitle">
                Please enter your details to create account
              </p>

              <div className="form-group">
                <label>Name</label>
                <div className="input-wrapper">
                  <input
                    value={name}
                    onChange={inputChange(setName)}
                    required
                    type="text"
                    placeholder="Enter your name"
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Surname</label>
                <div className="input-wrapper">
                  <input
                    value={surname}
                    onChange={inputChange(setSurname)}
                    required
                    type="text"
                    placeholder="Enter your surname"
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Email address</label>
                <div className="input-wrapper">
                  <input
                    value={email}
                    onChange={inputChange(setEmail)}
                    required
                    type="email"
                    placeholder="Enter email address"
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Password</label>
                <div className="input-wrapper">
                  <input
                    value={password}
                    onChange={inputChange(setPassword)}
                    required
                    type={passwordVisibility ? "text" : "password"}
                    placeholder="Enter password"
                  />
                  <button
                    type="button"
                    className="password-toggle"
                    onClick={toggleInput}
                  >
                    {passwordVisibility ? (
                      <img src={OpenEyeIcon} alt="OpenEye" />) : (<img src={ClosedEyeIcon} alt="ClosedEye" />)
                    }
                  </button>
                </div>
              </div>

              <button type="submit" className="signup-button">
                Sign Up
              </button>

              <div className="signup-links">
                <Link to="/forgot-password" className="forgot-password-link">
                  Forgot Password?
                </Link>

                <div className="login-link">
                  Already have an account?
                  <Link to="/login">Log in</Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default SignUp;
