// ForgotPassword.jsx
import React, { useState } from "react";
import "../authentication/scss/forgotpassword.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.post("https://law-quiz-backend.onrender.com/auth/resetpassword", {
        email,
      });

      if (response.status === 200) {
        toast.success("Reset link sent to your email!");
        setEmail(""); // Clear the email field after success
      } else {
        toast.error("We could not send a reset email!");
      }
    } catch (error) {
      console.error("Error details:", error.response?.data);
      toast.error(
        error.response?.data?.message ||
          "Failed to send reset email. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="forgot-password">
      <div className="container">
        <div className="forgot-password-content">
          <div className="forgot-password-card">
            <form className="forgot-password-form" onSubmit={handleSubmit}>
              <h2 className="forgot-password-title">Forgot Password?</h2>
              <p className="forgot-password-subtitle">
                Enter your email address and we'll send you a link to reset your
                password
              </p>

              <div className="form-group">
                <label>Email address</label>
                <div className="input-wrapper">
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    type="email"
                    placeholder="Enter your email address"
                  />
                </div>
              </div>

              <button
                type="submit"
                className="forgot-password-button"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Sending..." : "Send Reset Link"}
              </button>

              <div className="login-prompt">
                Remember your password?
                <Link to="/login">Log in</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
}
